<template>
  <div id="navbar">
    <!-- 导航栏 -->
    <Navigation />

    <!-- 内容区域 -->
    <AppMain />
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'
import AppMain from './components/AppMain.vue'

export default {
  name: 'Navbar',
  components: {
    Navigation,
    AppMain
  }
}
</script>

<style lang="scss" scoped>
// #navbar {
//     height: 100px;
//     display: flex;
//     justify-content: space-around;
// }
</style>
