<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="['News']">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    // cachedViews() {
    //   return this.$store.state.tagsView.cachedViews
    // },
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 100px);
  width: 100%;
  position: relative;
  overflow: hidden;

  margin-top: 100px;
}

/* fade-transform */
// .fade-transform-leave-active,
// .fade-transform-enter-active {
//   transition: all 0.3s;
// }

// .fade-transform-enter {
//   opacity: 0;
//   // transform: translateX(-30px);
// }

// .fade-transform-leave-to {
//   opacity: 0;
//   // transform: translateX(30px);
// }
</style>
