<template>
  <div class="container">
    <div id="navigation" class="container-w1200">
      <!-- logo -->
      <div class="logo" @click="$router.push({ name: 'Home' })">
        <img
          src="@/assets/images/logo@2x.png"
          alt="中天云网"
          class="logo-img"
        />
      </div>

      <!-- 导航 按钮 -->
      <div class="navigation-bar">
        <span
          class="nav-item"
          :class="{
            'vertical-line': index !== navList.length - 1
          }"
          v-for="(item, index) in navList"
          :key="item.name"
        >
          <a
            class="nav-item-a"
            :class="{
              'nav-item-active': active === item.name
            }"
            @click="toPage(item)"
            >{{ item.meta.title }}</a
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from '@/router'

export default {
  name: 'Navigation',
  data() {
    return {
      active: 'Home',
      // 导航菜单
      navList: []
    }
  },

  mounted() {
    this.initActive()

    this.getRoutes()

    // 通过 版权栏跳转页面 并设置 active
    this.$EventBus.$on('setActive', (name) => {
      this.active = name
      sessionStorage.setItem('NavActive', name)
    })
  },

  methods: {
    toPage(item) {
      // console.log(item)
      if (item.name === this.$route.name) {
        return
      }
      // sessionStorage.setItem('NavActive', item.name)
      // this.active = item.name
      this.$router.push({ name: item.name })
    },

    // 初始化 active
    initActive() {
      const NavActive = sessionStorage.getItem('NavActive')
      // console.log(NavActive)
      if (!NavActive) {
        // 没有 NavActive 则默认设置为 home
        sessionStorage.setItem('NavActive', 'Home')
      } else {
        this.active = NavActive
      }
    },

    // 设置菜单栏
    getRoutes() {
      const route = []
      routes.forEach((item) => {
        route.push(item.children[0])
      })
      this.navList = route
      // console.log('nav', route)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  top: 0px;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 3px 5px RGBA(221, 221, 221, 0.5);
}

#navigation {
  display: flex;
  justify-content: space-between;
  height: 100px;
  min-width: 810px;
  overflow: auto;

  // width: 100%;
}

.logo {
  height: 100px;
  line-height: 100px;
  cursor: pointer;

  .logo-img {
    width: 187px;
    object-fit: cover;
    // height: 42px;
    // margin-top: 8px;
    margin-left: 20px;
    image-rendering: -webkit-optimize-contrast;
  }
}

.navigation-bar {
  height: 100px;
  line-height: 100px;

  .nav-item {
    padding: 0 20px;
    color: #333;
    cursor: pointer;
    user-select: none;
  }

  .nav-item-a {
    padding: 6px 0;
  }

  .vertical-line {
    border-right: 1px solid #c9c9c9;
  }

  .nav-item-active {
    color: #08467c;
    border-bottom: 2px solid #08467c;;
  }
}
</style>
