import Vue from 'vue'
import VueRouter from 'vue-router'
import Navbar from '@/navbar'

Vue.use(VueRouter)

// 解决导航栏中的重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export const routes = [
  {
    path: '/',
    component: Navbar,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home/home'),
        meta: { title: '首页' }
      }
    ]
  },
  {
    path: '/center',
    component: Navbar,
    redirect: '/centerIndex',
    children: [
      {
        path: 'centerIndex',
        component: () => import('@/views/center/center'),
        name: 'Center',
        meta: { title: '产品中心' }
        // children: [
        //   {
        //     path: 'smartCity',
        //     name: 'SmartCity',
        //     // component: () => import('@/views/center/center'),
        //     meta: { title: 'AI智慧城市' }
        //   },
        //   {
        //     path: 'urbanInternet',
        //     name: 'UrbanInternet',
        //     // component: () => import('@/views/center/center'),
        //     meta: { title: '城市互联网' }
        //   },
        //   {
        //     path: 'moreproducts',
        //     name: 'MoreProducts',
        //     // component: () => import('@/views/center/center'),
        //     meta: { title: '更多产品' }
        //   }
        // ]
      }
    ]
  },
  // {
  //   path: '/case',
  //   component: Navbar,
  //   redirect: '/caseIndex',
  //   children: [
  //     {
  //       path: 'caseIndex',
  //       component: () => import('@/views/case/case'),
  //       name: 'Case',
  //       meta: { title: '经典案例' }
  //     }
  //   ]
  // },
  {
    path: '/news',
    component: Navbar,
    redirect: '/newsIndex',
    children: [
      {
        path: 'newsIndex',
        component: () => import('@/views/news/news'),
        name: 'News',
        meta: { title: '新闻资讯' }
      },
      {
        path: 'newsdetail',
        name: 'Newsdetail',
        component: () => import('@/views/news-detail/newsdetail'),
        meta: { title: '新闻资讯-详情' },
        hidden: true
      }
    ]
  },
  {
    path: '/outline',
    component: Navbar,
    redirect: '/outlineIndex',
    children: [
      {
        path: 'outlineIndex',
        component: () => import('@/views/outline/outline'),
        name: 'Outline',
        meta: { title: '公司概要' }
      }
    ]
  },
  {
    path: '/we',
    component: Navbar,
    redirect: '/weIndex',
    children: [
      {
        path: 'weIndex',
        component: () => import('@/views/we/we'),
        name: 'We',
        meta: { title: '联系我们' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // 如果从 新闻详情页 跳转到 新闻资讯页面，页面滚动到之前的高度
    // if (from.name === 'Newsdetail' && to.name === 'News') {
    //   const top = sessionStorage.getItem('top')
    //   // return { x: 0, y: Number(top), behavior: 'smooth' }
    //   return { x: 0 }
    // } else
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  },
  routes
})

export default router
