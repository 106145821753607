import Vue from 'vue'
import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress 配置

// 导航前置守卫
router.beforeEach((to, from, next) => {
  NProgress.start()

  document.title = getPageTitle(to.meta.title)
  next()
  // 设置  active navbar
  if (to.name === 'Newsdetail') {
    // 如果要跳转到 新闻详情，则激活 新闻资讯
    Vue.prototype.$EventBus.$emit('setActive', 'News')
  } else {
    Vue.prototype.$EventBus.$emit('setActive', to.name)
  }

  NProgress.done()
})

// 导航后置守卫
router.afterEach(() => {
  // end progress end
  NProgress.done()

  // 跳转页面后 页面滚动到顶部
  // document.body.scrollTop = 0
  // document.documentElement.scrollTop = 0
})
