/**
 * 按需导入 element 组件
 */

import Vue from 'vue'

import {
  Button,
  Carousel,
  CarouselItem,
  Backtop,
  Icon,
  Image,
  Pagination,
  Breadcrumb,
  BreadcrumbItem
} from 'element-ui'

Vue.prototype.$ELEMENT = {
  size: 'small'
  // zIndex: 3000
}

Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Backtop)
Vue.use(Icon)
Vue.use(Image)
Vue.use(Pagination)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
